<template>
  <div class="filter-component__filter-container mt-45">
    <div class="filter-component__search-additional">
      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Страна</p>
          <SelectMultiComponentCheckbox
            v-model="filter.countryIds"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="countryList"
          />
        </div>
      </div>

      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Группа стран</p>
          <SelectMultiComponentCheckbox
            v-model="filter.countryGroupIds"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="countryGroupList"
          />
        </div>
      </div>

      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Вид предмета торговли</p>
          <SelectSingleComponentCheckbox
            v-model="filter.tradeItemTypeId"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="tradeItemTypeList"
          />
        </div>
      </div>

      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Отрасль</p>
          <SelectMultiComponentCheckbox
            v-model="filter.aggregateIndustryIds"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="filteredIndustryList"
          />
        </div>
      </div>

      <div class="filter-component__search-additional-col">
        <FilterInput v-model="filter.vedName" :label="getLabelForVedName" placeholder="Введите" modClass="redesigned" />
      </div>

      <div class="filter-component__search-additional-col">
        <FilterInput v-model="filter.vedCode" :label="getLabelForVedCode" placeholder="Введите" modClass="redesigned" />
      </div>

      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Категория барьера</p>
          <SelectMultiComponentCheckbox
            v-model="filter.barrierCategoryIds"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="getBarrierCategoryList"
          />
        </div>
      </div>

      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Вид барьера</p>
          <SelectMultiComponentCheckbox
            v-model="filter.measureKindIds"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="getMeasureKindList"
          />
        </div>
      </div>
    </div>
    <!-- ////////////////////////////////////////////////////////////////////////////////////////// -->
    <div class="filter-component__search-additional">
      <div class="filter-component__search-additional flex-column">
        <div class="filter-component__search-additional-col">
          <div class="filter-component__search-additional-inputBlock">
            <p class="label">Барьер активен</p>
            <SelectSingleComponentCheckbox
              v-model="isBarrierActiveValue"
              placeholder="Выберите"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              :options="isBarrierBooleanList"
            />
          </div>
        </div>

        <div class="filter-component__search-additional-col">
          <div class="filter-component__search-additional-inputBlock">
            <p class="label">Период выявления барьера</p>
            <FilterInput
              type="date"
              v-model="filter.barrierIdentificationFrom"
              id="BarrierActiveBeginDate"
              labelForDate="От"
              placeholderForDate=""
              modClass="redesigned"
              :clearable="false"
              :upperLimit="upperLimitBarrierIdentification"
              dateIcon
            />
            <FilterInput
              type="date"
              v-model="filter.barrierIdentificationTo"
              id="BarrierActiveEndDate"
              labelForDate="До"
              placeholderForDate=""
              modClass="redesigned"
              :clearable="false"
              :lowerLimit="lowerLimitBarrierIdentification"
              dateIcon
            />
          </div>
        </div>
      </div>

      <div class="filter-component__search-additional flex-column">
        <div class="filter-component__search-additional-col">
          <div class="filter-component__search-additional-inputBlock">
            <p class="label">Барьер либерализован</p>
            <SelectSingleComponentCheckbox
              v-model="isBarrierLiberalizedValue"
              placeholder="Выберите"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              :options="isBarrierBooleanList"
            />
          </div>
        </div>

        <div class="filter-component__search-additional">
          <div class="filter-component__search-additional-col">
            <div class="filter-component__search-additional-inputBlock">
              <p class="label">Период либерализации барьера</p>
              <FilterInput
                v-model="filter.liberalizationDateFrom"
                type="date"
                id="BarrierLiberalizedBeginDate"
                labelForDate="От"
                placeholderForDate=""
                modClass="redesigned"
                :clearable="false"
                :upperLimit="upperLimitLiberalizationDate"
                dateIcon
              />
              <FilterInput
                v-model="filter.liberalizationDateTo"
                type="date"
                id="BarrierLiberalizedEndDate"
                labelForDate="До"
                placeholderForDate=""
                modClass="redesigned"
                :clearable="false"
                :lowerLimit="lowerLimitLiberalizationDate"
                dateIcon
              />
            </div>
          </div>

          <div class="filter-component__search-additional-col">
            <div class="filter-component__search-additional-inputBlock">
              <p class="label">Год последней либерализации</p>
              <FilterInput
                v-model="filter.lastLiberalizationYearFrom"
                type="date"
                id="lastLiberalizationYearFrom"
                labelForDate="От"
                placeholderForDate=""
                modClass="redesigned"
                :clearable="false"
                dateFormatProps="y"
                minimumViewDate="year"
                :upperLimit="upperLimitLastLiberalizationYear"
              />
              <FilterInput
                v-model="filter.lastLiberalizationYearTo"
                type="date"
                id="lastLiberalizationYearTo"
                labelForDate="До"
                placeholderForDate=""
                modClass="redesigned"
                :clearable="false"
                dateFormatProps="y"
                minimumViewDate="year"
                :lowerLimit="lowerLimitLastLiberalizationYear"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ////////////////////////////////////////////////////////////////////////////////////////// -->
    <div class="filter-component__search-additional align-items-end">
      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Сумма предотвращенного ущерба млн$/год</p>
          <FilterInput v-model="filter.preventedDamageAmountFrom" type="number" labelLeft="От" modClass="redesigned" />
          <FilterInput v-model="filter.preventedDamageAmountTo" type="number" labelLeft="До" modClass="redesigned" />
        </div>
      </div>

      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Сумма предполагаемого ущерба млн$/год</p>
          <FilterInput v-model="filter.allegedDamageAmountFrom" type="number" labelLeft="От" modClass="redesigned" />
          <FilterInput v-model="filter.allegedDamageAmountTo" type="number" labelLeft="До" modClass="redesigned" />
        </div>
      </div>

      <div class="filter-component__search-additional">
        <div class="filter-component__search-additional-col">
          <div class="filter-component__search-additional-inputBlock">
            <p class="label">Период устранения</p>
            <FilterInput
              v-model="filter.barrierRemovalFrom"
              type="date"
              id="eliminationDateFrom"
              labelForDate="От"
              placeholderForDate=""
              modClass="redesigned"
              :clearable="false"
              :upperLimit="upperLimitBarrierRemoval"
              dateIcon
            />
            <FilterInput
              v-model="filter.barrierRemovalTo"
              type="date"
              id="eliminationDateTo"
              labelForDate="До"
              placeholderForDate=""
              modClass="redesigned"
              :clearable="false"
              :lowerLimit="lowerLimitBarrierRemoval"
              dateIcon
            />
          </div>
        </div>
      </div>
    </div>

    <!-- ////////////////////////////////////////////////////////////////////////////////////////// -->
    <div class="fourth-flex-container filter-component__search-additional align-items-end">
      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Мера защиты рынка</p>
          <SelectMultiComponentCheckbox
            v-model="filter.defenceMeasureIds"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="defenceMeasureList"
          />
        </div>
      </div>

      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Орган, ответственный за введение меры</p>
          <SelectMultiComponentCheckbox
            v-model="filter.responsibleOrgIds"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="organList"
          />
        </div>
      </div>

      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Ответственный от Минэкономразвития России</p>
          <SelectMultiComponentCheckbox
            v-model="filter.responsibleEmployeeIds"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="employeeList"
          />
        </div>
      </div>
    </div>
    <!-- ////////////////////////////////////////////////////////////////////////////////////////// -->
    <div class="fifth-flex-container filter-component__search-additional">
      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Период введения</p>
          <FilterInput
            v-model="filter.beginDateFrom"
            type="date"
            id="EnterDateFrom"
            labelForDate="От"
            placeholderForDate=""
            modClass="redesigned"
            :clearable="false"
            :upperLimit="upperLimitBeginDate"
            dateIcon
          />
          <FilterInput
            v-model="filter.beginDateTo"
            type="date"
            id="EnterDateTo"
            labelForDate="До"
            placeholderForDate=""
            modClass="redesigned"
            :clearable="false"
            :lowerLimit="lowerLimitBeginDate"
            dateIcon
          />
        </div>
      </div>

      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Период окончания</p>
          <FilterInput
            v-model="filter.endDateFrom"
            type="date"
            id="endDateFrom"
            labelForDate="От"
            placeholderForDate=""
            modClass="redesigned"
            :clearable="false"
            :upperLimit="upperLimitEndDate"
            dateIcon
          />
          <FilterInput
            v-model="filter.endDateTo"
            type="date"
            id="endDateTo"
            labelForDate="До"
            placeholderForDate=""
            modClass="redesigned"
            :clearable="false"
            :lowerLimit="lowerLimitEndDate"
            dateIcon
          />
        </div>
      </div>

      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Период пересмотра меры</p>
          <FilterInput
            v-model="filter.revisionStartDateFrom"
            type="date"
            id="revisionStartDateFrom"
            labelForDate="От"
            placeholderForDate=""
            modClass="redesigned"
            :clearable="false"
            :upperLimit="upperLimitRevisionDate"
            dateIcon
          />
          <FilterInput
            v-model="filter.revisionStartDateTo"
            type="date"
            id="revisionStartDateTo"
            labelForDate="До"
            placeholderForDate=""
            modClass="redesigned"
            :clearable="false"
            :lowerLimit="lowerLimitRevisionDate"
            dateIcon
          />
        </div>
      </div>
    </div>

    <!-- ////////////////////////////////////////////////////////////////////////////////////////// -->
    <div class="filter-component__search-additional">
      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Период создания ограничения</p>
          <FilterInput
            v-model="filter.createdFrom"
            type="date"
            id="createdDateFrom"
            labelForDate="От"
            placeholderForDate=""
            modClass="redesigned"
            :clearable="false"
            :upperLimit="upperLimitCreateDate"
            dateIcon
          />
          <FilterInput
            v-model="filter.createdTo"
            type="date"
            id="createdDateTo"
            labelForDate="До"
            placeholderForDate=""
            modClass="redesigned"
            :clearable="false"
            :lowerLimit="lowerLimitCreateDate"
            dateIcon
          />
        </div>
      </div>

      <div class="filter-component__search-additional-col">
        <div class="filter-component__search-additional-inputBlock">
          <p class="label">Период изменения ограничения</p>
          <FilterInput
            v-model="filter.modifiedFrom"
            type="date"
            id="modifiedFrom"
            labelForDate="От"
            placeholderForDate=""
            modClass="redesigned"
            :clearable="false"
            :upperLimit="upperLimitModifiedDate"
            dateIcon
          />
          <FilterInput
            v-model="filter.modifiedTo"
            type="date"
            id="modifiedTo"
            labelForDate="До"
            placeholderForDate=""
            modClass="redesigned"
            :clearable="false"
            :lowerLimit="lowerLimitModifiedDate"
            dateIcon
          />
        </div>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
  import moment from 'moment';

  import FilterInput from '@/common/components/FilterInput';
  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import Constants from '@/common/constants';
  import Utils from '@/common/utils';

  import OrgApi from '@/modules/admin/api/organization';
  import UserApi from '@/modules/admin/api/users';
  import CountryApi from '@/modules/nsi/api/country';
  import CountryGroupApi from '@/modules/nsi/api/countryGroup';
  import NsiApi from '@/modules/nsi/api/nsi';

  import { DEFAULT_BARRIER_CATEGORY_LIST, DEFAULT_FILTER_VALUES } from '../utils/consts';

  export default {
    components: {
      FilterInput,
      SelectMultiComponentCheckbox,
      SelectSingleComponentCheckbox,
    },
    props: {
      filterProps: {
        type: Object,
        required: true,
        default: () => DEFAULT_FILTER_VALUES,
      },
    },
    data() {
      return {
        filter: this.filterProps,

        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          publicSortColumn: 0,
        },
        defaultRequest: {
          pageNumber: 1,
          pageSize: 0,
          isActive: true,
          includePicture: false,
        },
        countryList: [],
        countryGroupList: [],
        statusList: [],
        tradeItemTypeList: [],
        industryList: [],
        filteredIndustryList: this.industryList,
        barrierCategoryList: DEFAULT_BARRIER_CATEGORY_LIST,
        measureKindList: [],
        defenceMeasureList: [],
        isBarrierBooleanList: [
          {
            id: true,
            text: 'Да',
          },
          {
            id: false,
            text: 'Нет',
          },
        ],
        organList: [],
        employeeList: [],
      };
    },
    created() {
      this.loadDictionaries();
    },

    watch: {
      'filter.countryGroupIds'(value) {
        if (!value) return;
        this.filter.countryIds = this.countryList
          .filter((x) => value.includes(String(x.countryGroupId)))
          .map((x) => x.id);
      },
      'filter.tradeItemTypeId'(value) {
        value === null
          ? (this.filteredIndustryList = this.industryList)
          : value === String(Constants.tradeItemType.tnved)
            ? (this.filteredIndustryList = this.industryList.filter((item) => item.id.includes(';1')))
            : (this.filteredIndustryList = this.industryList.filter((item) => item.id.includes(';2')));
      },
    },

    methods: {
      loadDictionaries() {
        Utils.loadSelectOptions('restrictionMeasureStatusList', this.statusList, true);

        NsiApi.search('nsitradeitemtype', this.defaultRequest)
          .then((response) => {
            this.tradeItemTypeList = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        NsiApi.search('nsiindustry', this.defaultRequest)
          .then((response) => {
            this.industryList = this.industryList.concat(
              response.data.items.map((x) => {
                return { text: x.name, id: x.id + ';2' };
              }),
            );
            this.filteredIndustryList = this.industryList;
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        NsiApi.search('nsitnvedindustry', this.defaultRequest)
          .then((response) => {
            this.industryList = this.industryList.concat(
              response.data.items.map((x) => {
                return { text: x.name, id: x.id + ';1' };
              }),
            );
            this.filteredIndustryList = this.industryList;
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        NsiApi.search('nsimeasurekind', this.defaultRequest)
          .then((response) => {
            this.measureKindList = response.data.items.map((x) => {
              return {
                text: x.name,
                id: x.id,
                barrierCategoryId: x.barrierCategoryId,
                isActive: x.isActive,
              };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        NsiApi.search('nsimarketdefencemeasure', this.defaultRequest)
          .then((response) => {
            this.defenceMeasureList = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        CountryApi.search(this.defaultRequest)
          .then((response) => {
            this.countryList = response.data.items.map((x) => {
              return { text: x.name, id: x.id, countryGroupId: x.countryGroupId };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        CountryGroupApi.search(this.request)
          .then((response) => {
            this.countryGroupList = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        let request = Object.assign({ typeIds: [Constants.organizationType.foiv] }, this.defaultRequest);
        OrgApi.searchShortFormat(request)
          .then((response) => {
            this.organList = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        request = Object.assign({ organizationIds: [Constants.organizationMinekId] }, this.defaultRequest);
        UserApi.search(request)
          .then((response) => {
            this.employeeList = response.data.items.map((x) => {
              return { text: x.fullName, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
    },

    computed: {
      isBarrierLiberalizedValue: {
        get() {
          if (this.filter.isBarrierLiberalized) return 'true';
          if (this.filter.isBarrierLiberalized === null) return 'null';
          return 'false';
        },
        set(value) {
          if (value === 'true') this.filter.isBarrierLiberalized = true;
          else if (value === 'false') this.filter.isBarrierLiberalized = false;
          else this.filter.isBarrierLiberalized = null;
        },
      },
      isBarrierActiveValue: {
        get() {
          if (this.filter.isBarrierActive) return 'true';
          if (this.filter.isBarrierActive === null) return 'null';
          return 'false';
        },
        set(value) {
          if (value === 'true') this.filter.isBarrierActive = true;
          else if (value === 'false') this.filter.isBarrierActive = false;
          else this.filter.isBarrierActive = null;
        },
      },
      getLabelForVedName() {
        return this.filter.tradeItemTypeId === '1'
          ? 'Наименование товара'
          : this.filter.tradeItemTypeId === '2'
            ? 'Наименование услуги'
            : 'Наименование товара/услуги';
      },
      getLabelForVedCode() {
        return this.filter.tradeItemTypeId === null
          ? 'Код товара/услуги'
          : this.filter.tradeItemTypeId === '1'
            ? 'Код товара'
            : 'Код услуги';
      },
      getBarrierCategoryList() {
        return this.filter.tradeItemTypeId === null
          ? this.barrierCategoryList
          : this.barrierCategoryList.filter((item) => item.tradeItemTypeId == this.filter.tradeItemTypeId);
      },
      getMeasureKindList() {
        return this.filter.barrierCategoryIds.length === 0
          ? this.measureKindList
          : this.measureKindList.filter((item) =>
              this.filter.barrierCategoryIds.some((barrierCategory) => barrierCategory == item.barrierCategoryId),
            );
      },
      lowerLimitBeginDate() {
        return moment(this.filter.beginDateFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      upperLimitBeginDate() {
        return moment(this.filter.beginDateTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      lowerLimitEndDate() {
        return moment(this.filter.endDateFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      upperLimitEndDate() {
        return moment(this.filter.endDateTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      lowerLimitRevisionDate() {
        return moment(this.filter.revisionStartDateFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      upperLimitRevisionDate() {
        return moment(this.filter.revisionStartDateTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      lowerLimitCreateDate() {
        return moment(this.filter.createdFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      upperLimitCreateDate() {
        return moment(this.filter.createdTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      lowerLimitModifiedDate() {
        return moment(this.filter.modifiedFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      upperLimitModifiedDate() {
        return moment(this.filter.modifiedTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      lowerLimitLastLiberalizationYear() {
        return moment(this.filter.lastLiberalizationYearFrom, Constants.DATE_FORMATS.YYYY).toDate();
      },
      upperLimitLastLiberalizationYear() {
        return moment(this.filter.lastLiberalizationYearTo, Constants.DATE_FORMATS.YYYY).toDate();
      },
      lowerLimitBarrierIdentification() {
        return moment(this.filter.barrierIdentificationFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      upperLimitBarrierIdentification() {
        return moment(this.filter.barrierIdentificationTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      lowerLimitLiberalizationDate() {
        return moment(this.filter.liberalizationDateFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      upperLimitLiberalizationDate() {
        return moment(this.filter.liberalizationDateTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      lowerLimitBarrierRemoval() {
        return moment(this.filter.barrierRemovalFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      upperLimitBarrierRemoval() {
        return moment(this.filter.barrierRemovalTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../styles/filterComponent.scss';
</style>
